import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { register as registerServiceWorker, unregister as unregisterServiceWorker  } from './serviceWorkerRegistration';

import Route from './Route.js';

import LoadingSpinner from './inc/Loading';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { Suspense } from 'react';
import WebApp from '@twa-dev/sdk'

WebApp.ready();
WebApp.expand();



const router = createBrowserRouter([
  {
    path: "/",
    element: <Route page="main" />,
  },
  {
    path: "/my/land",
    element: <Route page="my-land" />,
  },
  {
    path: "/my",
    element: <Route page="my" />,
  },
  {
    path: "/my-app",
    element: <Route page="my-app" />,
  },
  {
    path: "/my/connect",
    element: <Route page="my-connect" />,
  },
  {
    path: "/app/auth",
    element: <Route page="AppAuth" />,
  },
  {
    path: "/service/acp",
    element: <Route page="acp-app" />,
  },
  {
    path: "/service/acp/auth",
    element: <Route page="acp-auth" />,
  },
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
var visibilitychange = ()=>{
  try{
    window.visibilitychange();
  }catch(e){}
};
document.addEventListener("visibilitychange", visibilitychange, false);
root.render(

  <Suspense fallback={<LoadingSpinner/>}>

 <RouterProvider router={router} />
</Suspense>


);


registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
